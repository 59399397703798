<template>
  <div class="steps-section-list">
    <div class="steps-section-item">
      <div class="step-number">01</div>
      <h3 class="step-title">Создайте портфолио</h3>
      <p class="step-description">
        Расскажите о себе и добавьте работы.
      </p>
    </div>
    <div class="steps-section-item">
      <div class="step-number">02</div>
      <h3 class="step-title">Участвуйте в интересных проектах</h3>
      <p class="step-description">
        Просматривайте опен-коллы, выбирайте подходящие для себя проекты и отправляйте отклик.
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>