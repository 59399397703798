<template>
  <div class="how-it-works" id="how-it-works">
    <h2 class="section-title">Как это работает</h2>

    <!-- TABS   -->
    <div class="pt-10 md:pt-20">

      <BaseTabs :tabList="tabList">
        <template v-slot:tabPanel-1> <HomeHowItWorksContentArtist /> </template>
        <template v-slot:tabPanel-2> <HomeHowItWorksContentCurator /> </template>
        <template v-slot:tabPanel-3> <HomeHowItWorksContentGallery /> </template>
<!--        <template v-slot:tabPanel-4> <HomeHowItWorksContentMuseums /> </template>-->
<!--        <template v-slot:tabPanel-5> <HomeHowItWorksContentArtResidences /> </template>-->
<!--        <template v-slot:tabPanel-6> <HomeHowItWorksContentClients /> </template>-->
      </BaseTabs>

    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      tabList: [ "Художникам", "Кураторам", "Арт-пространствам" ],
    };
  },
};
</script>

<style>
.how-it-works {
  @apply
  mb-24
}

.section-title {
  @apply
  text-4xl
  font-semibold
}

.steps-section-list {
  @apply
  flex
  flex-col
  md:flex-row
  flex-wrap
  mt-10
  mb-32
  space-y-0
}

.steps-section-item {
  @apply
  sm:w-full
  md:w-1/2
  lg:w-1/3
  p-5
}

.step-number {
  @apply
  mb-8
  w-20
  text-primary-light
  dark:text-primary-dark
}

.step-title {
  @apply
  text-xl
  font-semibold
}

.step-description {
  @apply
  text-sm
  font-light
  my-5
}
</style>