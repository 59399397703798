<template>
  <div class="steps-section-list">
    <div class="steps-section-item">
      <div class="step-number">01</div>
      <h3 class="step-title">Создайте портфолио</h3>
      <p class="step-description">
        Расскажите о себе
      </p>
    </div>
    <div class="steps-section-item">
      <div class="step-number">02</div>
      <h3 class="step-title">Находите художников для своих идей и концептов</h3>
      <p class="step-description">
        Самостоятельно в галерее или с помощью опен-колла подберите художников, которые смогут раскрыть идею вашего проекта
      </p>
    </div>
    <div class="steps-section-item">
      <div class="step-number">03</div>
      <h3 class="step-title">Показывайте свои проекты на выставках</h3>
      <p class="step-description">
        Создайте опен-колл для галерей и выставочных пространств с описанием своего проекта, дождитесь откликов и выбирайте подходящее для проекта пространство
      </p>
    </div>
  </div>
</template>