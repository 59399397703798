<template>
  <div class="steps-section-list">
    <div class="steps-section-item">
      <div class="step-number">01</div>
      <h3 class="step-title">
        Создайте личный кабинет
      </h3>
      <p class="step-description">
        Напишите о своем пространстве, о специфике вашей работы, разместите фото
      </p>
    </div>
    <div class="steps-section-item">
      <div class="step-number">02</div>
      <h3 class="step-title">Проводите выставки интересных художников и кураторских проектов</h3>
      <p class="step-description">
        Создайте опен-колл со своим запросом либо откликайтесь на опен-коллы кураторов
      </p>
    </div>
    <div class="steps-section-item">
      <div class="step-number">03</div>
      <h3 class="step-title">Показывайте свои проекты на других площадках</h3>
      <p class="step-description">
        Создайте опен-колл с описанием своего проекта для других галерей и городов
      </p>
    </div>
  </div>
</template>